<script>
import Pagination from '@/components/general/Pagination'
import WorkspaceContentsTabTemplate from '@/components/workspace/WorkspaceContentsTabTemplate'
import Loading from '../general/Loading'
import ErrorList from '@/components/general/ErrorList'
export default {
  name: 'HomepageUserContents',
  components: {
    WorkspaceContentsTabTemplate,
    Pagination,
    Loading,
    ErrorList
  },
  data () {
    return {
      pagination: {
        limit: 15,
        page: 1,
        totalPages: 1,
        totalItems: -1
      },
      contents: [],
      appliedFilters: null,
      isLoading: true,
      errorState: false
    }
  },
  watch: {
    'pagination.page' (page) {
      this.$router.push({ name: this.selectedWorkspace.type + '.home.user.contents', query: { page } })
    },
    '$route' (_, to) {
      if (to.name.includes('contents.published')) {
        this.updateContents(this.appliedFilters)
      }
    },
    appliedFilters () {
      this.pagination.page = 1
    }
  },
  computed: {
    workspaceId () {
      return this.selectedWorkspace.type === 'business' ? this.selectedWorkspace.lxID : this.$store.getters.getUser.individualWorkspace ? this.$store.getters.getUser.individualWorkspace.id : null
    }
  },
  methods: {
    async updateContents (filter = {}) {
      this.appliedFilters = filter
      await this.synchronizeUser()
      const payload = await this.$store.dispatch('attemptGetWorkspaceContents', {
        workspaceId: this.workspaceId,
        filter,
        limit: this.pagination.limit,
        page: this.pagination.page
      })
      if (payload && payload.error) this.errorState = true
      else {
        this.contents = payload.data
        this.pagination.totalPages = payload.totalPages
        this.pagination.totalItems = payload.total
        this.smoothScrollTop()
      }
      this.isLoading = false
    },
    setup () {
      if (this.$route.query.page) {
        this.pagination.page = parseInt(this.$route.query.page)
      }
      this.updateContents()
    },
    showModalAddListContent (params) {
      this.$emit('showModalAddListContent', params)
    }
  },
  created () {
    this.setup()
  }
}
</script>
<template>
  <div class="homepage-user-contents--container" >
    <Loading class="loading-candidature--wrapper" v-if="isLoading"/>
    <error-list :title="this.$t('empty.state.text.published:error.text')" v-else-if="errorState && !isLoading"></error-list>
    <div v-else>
      <workspace-contents-tab-template :contents="contents" @updateList="updateContents" @showModalAddListContent="params => showModalAddListContent(params)"/>
      <pagination v-if="this.pagination.totalItems" :pagination="pagination"></pagination>
      <router-view></router-view>
    </div>
  </div>
</template>
<style lang="scss">
.homepage-user-contents--container {
  padding-bottom: 40px;

  .empty-list{
    margin-top: 20px;
  }

  .loading-candidature--wrapper {
    height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .homepage-user-contents--banner__wrapper {
    text-align: left;
    padding: 50px 24px;

    >* {
      margin: 0;
    }
  }
}
</style>
